<template>
	<router-view></router-view>
</template>

<script>

export default {
	
	mounted(){
		var self = this;
		this.mgr.signinRedirectCallback().then(function () {
			self.$router.push({name: 'loading'});
		});			
	}
}
</script>